import React, { useEffect, useRef } from 'react'
import { cx } from 'emotion'
import moment from 'moment'
import { Moment } from 'moment/moment'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { DesktopDateTimePicker } from '@mui/x-date-pickers'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { Course } from '@pages/employee/Profile/interfaces'
import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import { SectionEdit } from '@pages/employee/Profile/Partials/SectionEdit'
import { filterEmptyObjects } from '@pages/employee/Profile/utils'
import { Icon } from '@sharedComponents/React'

import {
	deleteInlineIcon,
	EditContentWrapper,
	EditItem,
	fieldWithRedAsterisk,
	formField,
} from '@pages/employee/Profile/Profile.Style'

interface Props {
	close: () => void
	returnData?: (data: Course[]) => void
	courses: Course[]
}

const emptyCourse: Course = {
	name: '',
	endDate: null,
}

const CoursesEdit = ({ close, courses, returnData }: Props) => {
	const { saveData, addItem, currentValue, removeItem, errors, handleChange } =
		useEditSection('courses', courses.length ? courses : [emptyCourse])
	const buttonRef = useRef<HTMLButtonElement>(null)
	const itemAdded = useRef<boolean>(false)

	const handleSave = async () => {
		let shouldClose = false
		if (returnData) {
			returnData(filterEmptyObjects(currentValue))
			shouldClose = true
		} else {
			shouldClose = await saveData()
		}
		if (shouldClose) {
			close()
		}
	}

	useEffect(() => {
		if (!itemAdded.current) return

		buttonRef.current.scrollIntoView({
			behavior: 'smooth',
		})
		itemAdded.current = false
	}, [currentValue])

	return (
		<SectionEdit header='Kursy / szkolenia' closeFn={ close } saveFn={ handleSave }>
			<EditContentWrapper>
				{ currentValue.map((course, index) => (
					<EditItem key={ index } applyMargin>
						<div>
							<TextField
								required
								value={ course.name }
								label='Nazwa kursu / szkolenia'
								variant='outlined'
								onChange={ ({ target }) =>
									handleChange(target.value, `[${ index }][name]`)
								}
								error={ errors && !!errors?.[index]?.name }
								helperText={ (errors && errors?.[index]?.name) || ' ' }
								fullWidth
								className={ cx(formField, fieldWithRedAsterisk) }
								multiline
							/>

							<DesktopDateTimePicker
								onChange={ (value: Moment) =>
									handleChange(
										value?.date(moment().date())?.format('YYYY-MM-DD') ?? null,
										`[${ index }][endDate]`,
									)
								}
								value={ course.endDate || null }
								label='Data zakończenia'
								views={ [ 'year', 'month' ] }
								inputFormat='yyyy-MM'
								minDate={ moment().subtract(99, 'years') }
								maxDate={ moment() }
								openTo='year'
								renderInput={ params => (
									<TextField
										{ ...params }
										className={ formField }
										fullWidth
										error={ errors && !!errors?.[index]?.endDate }
										helperText={ (errors && errors?.[index]?.endDate) || ' ' }
									/>
								) }
							/>
						</div>

						<RoundedButton
							icon='trash-can'
							color='error'
							onClick={ () => removeItem(index) }
							className={ deleteInlineIcon }
						/>
					</EditItem>
				)) }

				<Button
					ref={ buttonRef }
					onClick={ () => {
						addItem(emptyCourse)
						itemAdded.current = true
					} }
					startIcon={ <Icon type='plus' /> }
				>
					Dodaj kurs / szkolenie
				</Button>
			</EditContentWrapper>
		</SectionEdit>
	)
}

export default CoursesEdit
