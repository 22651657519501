import React, { useEffect, useRef, useState } from 'react'
import { cx } from 'emotion'
import { Moment } from 'moment'
import moment from 'moment/moment'

import { Icon } from '@components/React'
import { FormControlLabel, Switch } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { DesktopDateTimePicker } from '@mui/x-date-pickers'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import {
	BasicType,
	ProfessionalExperience,
} from '@pages/employee/Profile/interfaces'
import AutocompleteField from '@pages/employee/Profile/Partials/AutocompleteField'
import { RoundedButton } from '@pages/employee/Profile/Partials/RoundedButton'
import { SectionEdit } from '@pages/employee/Profile/Partials/SectionEdit'

import {
	basicSwitch,
	deleteInlineIcon,
	EditContentWrapper,
	EditItem,
	fieldWithRedAsterisk,
	formField,
} from '@pages/employee/Profile/Profile.Style'

interface Props {
	experience: ProfessionalExperience[]
	close: () => void
	editIndex: number | null
	returnData?: (data: ProfessionalExperience[]) => void
}

const emptyExperience: ProfessionalExperience = {
	position: { title: '', value: null },
	employer: { title: '', value: null },
	startDate: null,
	endDate: null,
	currentlyWorking: false,
	responsibilities: [],
}

const ExperienceEdit = ({
	experience,
	close,
	editIndex,
	returnData,
}: Props) => {
	const [ index, setIndex ] = useState<number | null>(editIndex)
	const buttonRef = useRef<HTMLButtonElement>(null)
	const itemAdded = useRef<boolean>(false)

	const {
		currentValue,
		addItem,
		removeItem,
		saveData,
		handleChange,
		errors,
		setErrors,
		setCurrentValue,
	} = useEditSection(
		'professionalExperience',
		experience.length ? experience : [],
	)

	useEffect(() => {
		if (typeof editIndex !== 'number') {
			setIndex(currentValue.length)
			addItem(emptyExperience)
		}
	}, [])

	useEffect(() => {
		if (currentValue[index]?.currentlyWorking) {
			handleChange(null, `[${ index }][endDate]`)
		}
	}, [currentValue[index]?.currentlyWorking])

	useEffect(() => {
		if (!itemAdded.current) return

		buttonRef.current.scrollIntoView({
			behavior: 'smooth',
		})
		itemAdded.current = false
	}, [currentValue[index]?.responsibilities])

	const handleSave = async () => {
		let shouldClose = false
		if (returnData) {
			returnData(currentValue)
			shouldClose = true
		} else {
			shouldClose = await saveData()
		}
		if (shouldClose) {
			close()
		}
	}

	const removeFn = async () => {
		if (currentValue[index]) {
			await removeItem(index, true)
			close()
		}
	}

	const removeResponsibility = (responsibilityIndex: number) => {
		setCurrentValue(prevState =>
			prevState.map((prevValue, prevValueIndex) => {
				if (index === prevValueIndex) {
					return {
						...prevValue,
						responsibilities: prevValue.responsibilities.filter(
							(_, index) => responsibilityIndex !== index,
						),
					}
				}
				return prevValue
			}),
		)

		if (errors?.[index]?.responsibilities) {
			setErrors(prevErrors => {
				const updatedErrors = {}

				Object.entries(prevErrors).forEach(([ key, value ]: [string, any]) => {
					if (Number(key) !== index) {
						updatedErrors[key] = value
					} else {
						updatedErrors[key] = {
							...value,
							responsibilities: value.responsibilities.filter(
								(_, i) => i !== responsibilityIndex,
							),
						}
					}
				})

				return updatedErrors
			})
		}
	}

	const addResponsibility = () => {
		handleChange(
			'',
			`[${ index }][responsibilities][${
				currentValue[index]?.responsibilities?.length || 0
			}]`,
		)
	}
	const defaultMinDate = moment().subtract(99, 'years')

	if (!currentValue[index]) {
		return null
	}

	const isEndDateRequired =
		currentValue[index]?.startDate && !currentValue[index]?.currentlyWorking

	return (
		<SectionEdit
			closeFn={ close }
			saveFn={ handleSave }
			header={ `${
				typeof editIndex === 'number' ? 'Edytuj' : 'Dodaj'
			} doświadczenie` }
			removeFn={ typeof editIndex === 'number' ? removeFn : null }
		>
			<EditContentWrapper>
				<AutocompleteField
					required
					type='position'
					label='Stanowisko'
					dataValue={ currentValue[index].position }
					error={ errors && errors?.[index]?.position?.title }
					handleChange={ (value: BasicType) =>
						handleChange(value, `[${ index }][position]`)
					}
				/>

				<AutocompleteField
					required
					type='employer'
					label='Nazwa firmy'
					dataValue={ currentValue[index].employer }
					error={ errors && errors?.[index]?.employer?.title }
					handleChange={ (value: BasicType) =>
						handleChange(value, `[${ index }][employer]`)
					}
				/>

				<DesktopDateTimePicker
					onChange={ (value: Moment) =>
						handleChange(
							value?.date(moment().date())?.format('YYYY-MM-DD') || null,
							`[${ index }][startDate]`,
						)
					}
					value={ currentValue[index].startDate || null }
					label='Od'
					views={ [ 'year', 'month' ] }
					inputFormat='yyyy-MM'
					minDate={ defaultMinDate }
					maxDate={ currentValue[index].endDate || moment() }
					openTo='year'
					renderInput={ params => (
						<TextField
							{ ...params }
							className={ cx(formField) }
							fullWidth
							error={ errors && !!errors?.[index]?.startDate }
							helperText={ (errors && errors?.[index]?.startDate) || ' ' }
						/>
					) }
				/>

				<DesktopDateTimePicker
					disabled={ currentValue[index].currentlyWorking }
					onChange={ (value: Moment) =>
						handleChange(
							value?.date(moment().date())?.format('YYYY-MM-DD'),
							`[${ index }][endDate]`,
						)
					}
					value={ currentValue[index].endDate || null }
					label='Do'
					views={ [ 'year', 'month' ] }
					inputFormat='yyyy-MM'
					minDate={ currentValue[index].startDate || defaultMinDate }
					maxDate={ moment() }
					openTo='year'
					renderInput={ params => (
						<TextField
							required={ isEndDateRequired }
							{ ...params }
							className={ cx(formField, fieldWithRedAsterisk) }
							fullWidth
							error={ errors && !!errors?.[index]?.endDate }
							helperText={ (errors && errors?.[index]?.endDate) || ' ' }
						/>
					) }
				/>

				<FormControlLabel
					className={ basicSwitch(currentValue[index].currentlyWorking) }
					control={
						<Switch
							color='default'
							checked={ currentValue[index].currentlyWorking }
							onChange={ ({ target }) =>
								handleChange(target.checked, `[${ index }][currentlyWorking]`)
							}
						/>
					}
					label='Obecnie tu pracuję'
				/>

				<p>Obowiązki:</p>

				{ currentValue[index].responsibilities?.map(
					(responsibility, responsibilityIndex) => (
						<EditItem key={ responsibilityIndex }>
							<TextField
								value={ responsibility }
								variant='outlined'
								onChange={ ({ target }) =>
									handleChange(
										target.value,
										`[${ index }][responsibilities][${ responsibilityIndex }]`,
									)
								}
								fullWidth
								className={ formField }
								error={ !!errors?.[index]?.responsibilities[responsibilityIndex] }
								helperText={
									errors?.[index]?.responsibilities[responsibilityIndex] || ' '
								}
								multiline
							/>

							<RoundedButton
								icon='trash-can'
								color='error'
								onClick={ () => removeResponsibility(responsibilityIndex) }
								className={ deleteInlineIcon }
							/>
						</EditItem>
					),
				) }

				<Button
					ref={ buttonRef }
					onClick={ () => {
						addResponsibility()
						itemAdded.current = true
					} }
					startIcon={ <Icon type='plus' /> }
				>
					Dodaj obowiązek
				</Button>
			</EditContentWrapper>
		</SectionEdit>
	)
}

export default ExperienceEdit
