import React, { useState } from 'react'

import { updateProfile } from '@pages/employee/Profile/api'
import { Preferences } from '@pages/employee/Profile/interfaces'
import { Icon } from '@sharedComponents/React'

import { RoundedButton } from '../../RoundedButton'

import ContractEdit from './ContractEdit'
import LocationEdit from './LocationEdit'
import PositionEdit from './PositionEdit'
import SalaryEdit from './SalaryEdit'

import {
	ContractsWrapper,
	EditableSection,
	iconMargin,
	InfoBadge,
	SectionContainer,
	SectionContent,
	SectionSubtitle,
	SectionTitle,
	TextButton,
	TitleWrapper,
} from '@pages/employee/Profile/Profile.Style'

export enum WorkModel {
	in_office = 'stacjonarny',
	hybrid = 'hybrydowy',
	remote = '100% zdalny',
	mobile = 'mobilny (w terenie)',
}

export enum ContractType {
	employment = 'umowa o pracę',
	specific_task = 'umowa o dzieło',
	mandatory = 'umowa zlecenie',
	b2b = 'kontrakt B2B',
	internship = 'umowa o staż/praktykę',
}

export enum PositionLevel {
	worker = 'pracownik fizyczny',
	trainee = 'praktykant/stażysta',
	assistant = 'asystent',
	junior_specialist = 'młodszy specjalista (junior)',
	specialist = 'specjalista (mid)',
	senior_specialist = 'starszy specjalista (senior)',
	expert = 'ekspert',
	mid_level_manager = 'menedżer średniego szczebla',
	senior_manager = 'menedżer wyższego szczebla',
}

type Props = {
	preferences: Preferences
}

export const PreferencesSection = ({ preferences }: Props) => {
	const [ currentlyEditedPart, setCurrentlyEditedPart ] = useState<
		keyof Preferences | null
	>(null)
	const { preferredPosition, preferredLocation, expectedSalary, contractType } =
		preferences

	const preferredLocationFilled =
		preferredLocation?.location ||
		preferredLocation?.radius ||
		preferredLocation?.relocation !== null ||
		preferredLocation?.workModel.length > 0

	const getExperienceYearsText = (years?: number) => {
		if (!years) {
			return ''
		}

		let text = 'lat'

		if (years === 1) {
			text = 'rok'
		} else if (
			years % 10 >= 2 &&
			years % 10 <= 4 &&
			!(years % 100 >= 12 && years % 100 <= 14)
		) {
			text = 'lata'
		}

		return `(${ years } ${ text })`
	}

	const handleSave = async (data: Preferences) => {
		const requestData: any = {
			...data,
			preferredLocation: {
				...data.preferredLocation,
				location: data?.preferredLocation?.location?.value || null,
			},
		}

		await updateProfile('preferences', requestData)
	}

	const handleCloseEdit = () => {
		setCurrentlyEditedPart(null)
	}

	const renderEdit = () => {
		switch (currentlyEditedPart) {
		case 'preferredPosition':
			return (
				<PositionEdit
					onSave={ handleSave }
					onClose={ handleCloseEdit }
					preferredPosition={ preferredPosition }
				/>
			)
		case 'preferredLocation':
			return (
				<LocationEdit
					onSave={ handleSave }
					onClose={ handleCloseEdit }
					preferredLocation={ preferredLocation }
				/>
			)
		case 'expectedSalary':
			return (
				<SalaryEdit
					onClose={ handleCloseEdit }
					onSave={ handleSave }
					expectedSalary={ expectedSalary }
				/>
			)
		case 'contractType':
			return (
				<ContractEdit
					onClose={ handleCloseEdit }
					onSave={ handleSave }
					contractType={ contractType }
				/>
			)
		default:
			return null
		}
	}

	return (
		<>
			<SectionContainer grayBackground>
				<SectionContent>
					<TitleWrapper>
						<SectionTitle>Preferencje zawodowe</SectionTitle>
						<InfoBadge>
							<Icon type='eye-slash' />
							<span>Sekcja widoczna tylko dla Ciebie</span>
						</InfoBadge>
					</TitleWrapper>

					<p>Uzupełnij dane, a my znajdziemy najlepsze propozycje pracy.</p>
				</SectionContent>

				<SectionContent>
					<SectionSubtitle>
						<Icon type='briefcase' className={ iconMargin } />
						Szukam pracy na stanowisku:
					</SectionSubtitle>

					{ preferredPosition ? (
						<EditableSection>
							<div>
								<p>{ preferredPosition.position.title }</p>
								{ preferredPosition.level && (
									<p>
										<span>{ PositionLevel[preferredPosition.level] }</span>
										<span>
											{ ' ' }
											{ getExperienceYearsText(
												preferredPosition.experienceYears,
											) }
										</span>
									</p>
								) }
							</div>
							<RoundedButton
								icon='pencil'
								onClick={ () => setCurrentlyEditedPart('preferredPosition') }
							/>
						</EditableSection>
					) : (
						<TextButton
							onClick={ () => setCurrentlyEditedPart('preferredPosition') }
						>
							+ Dodaj stanowisko
						</TextButton>
					) }
				</SectionContent>

				<SectionContent>
					<SectionSubtitle>
						<Icon type='location-dot' className={ iconMargin } />
						Miejsce i tryb pracy:
					</SectionSubtitle>

					{ preferredLocation && preferredLocationFilled ? (
						<EditableSection>
							<div>
								<p>{ preferredLocation.location?.title }</p>
								{ preferredLocation.radius !== null && (
									<p>+{ preferredLocation.radius } km</p>
								) }
								{ typeof preferredLocation.relocation === 'boolean' && (
									<p>
										<strong>Relokacja:</strong>{ ' ' }
										{ preferredLocation.relocation ? 'TAK' : 'NIE' }
									</p>
								) }
								{ preferredLocation?.workModel?.length > 0 && (
									<p>
										<strong>Tryby:</strong>{ ' ' }
										{ preferredLocation.workModel.map((workModel, index) => (
											<span key={ workModel }>
												{ WorkModel[workModel] }
												{ preferredLocation.workModel[index + 1] ? ', ' : '' }
											</span>
										)) }
									</p>
								) }
							</div>
							<RoundedButton
								icon='pencil'
								onClick={ () => setCurrentlyEditedPart('preferredLocation') }
							/>
						</EditableSection>
					) : (
						<TextButton
							onClick={ () => setCurrentlyEditedPart('preferredLocation') }
						>
							+ Dodaj miejsce i tryb pracy
						</TextButton>
					) }
				</SectionContent>

				<SectionContent>
					<SectionSubtitle>
						<Icon type='money-bill-wave' className={ iconMargin } />
						Oczekiwane wynagrodzenie:
					</SectionSubtitle>

					{ expectedSalary ? (
						<EditableSection>
							<div>
								<p>{ expectedSalary } PLN brutto</p>
							</div>
							<RoundedButton
								icon='pencil'
								onClick={ () => setCurrentlyEditedPart('expectedSalary') }
							/>
						</EditableSection>
					) : (
						<TextButton
							onClick={ () => setCurrentlyEditedPart('expectedSalary') }
						>
							+ Dodaj wynagrodzenie
						</TextButton>
					) }
				</SectionContent>

				<SectionContent>
					<SectionSubtitle>
						<Icon type='file-lines' className={ iconMargin } />
						Rodzaj umowy:
					</SectionSubtitle>

					{ contractType?.length > 0 ? (
						<EditableSection>
							<ContractsWrapper>
								{ contractType.map(
									(contract, index) =>
										`${ ContractType[contract] }${
											contractType?.length > 1 &&
											index !== contractType.length - 1
												? ', '
												: ''
										}`,
								) }
							</ContractsWrapper>
							<RoundedButton
								icon='pencil'
								onClick={ () => setCurrentlyEditedPart('contractType') }
							/>
						</EditableSection>
					) : (
						<TextButton onClick={ () => setCurrentlyEditedPart('contractType') }>
							+ Dodaj rodzaj umowy
						</TextButton>
					) }
				</SectionContent>
			</SectionContainer>
			{ renderEdit() }
		</>
	)
}
